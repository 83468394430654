<template>
  <div class="announcement-component">
    <!-- 公告列表组件 -->
    <div class="list">
      <!-- 每一项公告 -->
      <div
        class="affiche-item"
        v-for="(item, i) in tweetsList.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )"
        :key="i"
      >
        <!-- 左边标题 -->
        <div class="affiche-title">
          <span
            class="text"
            :class="{ 'title-active': titleActive === i }"
            @mouseover="mouseover(i)"
            @mouseout="mouseout()"
            @click="jumpDetailsPage(item.bzId)"
            >{{ item.bzTitle }}</span
          >
        </div>
        <!-- 右边日期 -->
        <div class="affiche-date">{{ item.createTime }}</div>
      </div>
    </div>

    <!-- 分页按钮列表 -->
    <div class="paging-btns">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- <div class="left-statistical">
        <span class="record-sum">共&nbsp;{{ total }} &nbsp;条记录</span>
        <span>第<i>1</i> / <i>80</i>条</span>
      </div> -->
      <!-- <div class="rigth-paging"> -->
      <!-- <van-pagination
          v-model="currentPage"
          :total-items="total"
          :show-page-size="showpage"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination> -->

      <!-- 页码数量 -->
      <!-- <div class="page-sum">
          <span>10条/页</span>
          <span class="bottom-arrow"></span>
        </div> -->

      <!-- 指定页码 -->
      <!-- <div class="appoint-page">
          <span>跳转</span>
          <input
            type="number"
            :value="currentPage"
            class="appoint-page-input"
            ref="inputs"
            @keyup.enter="press($event)"
          />
          <span>页</span>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import common from "../../../api/common";
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      showpage: 9,
      titleActive: NaN, // 鼠标以上标题添加高亮下标
      total: 0, // 推文总数量
      tweetsList: [], // 推文数据
    };
  },
  props: {
    ywType: {
      type: String,
      default: "招聘公告",
    },
  },
  created() {
    // 初始化分页数据
    this.getPagingtList();
  },
  methods: {
    //   标题鼠标移上添加高亮的方法
    mouseover(i) {
      this.titleActive = i;
    },
    //   标题鼠标移出去掉高亮类名的方法
    mouseout() {
      this.titleActive = NaN;
    },
    //   输入指定跳转页码的方法
    press() {
      this.currentPage = this.$refs.inputs.value - 0;
    },

    // 跳转招聘详情页
    jumpDetailsPage(bzId) {
      this.$router.push({
        name: "RecruitDetaiksHelp",
        params: {
          id: bzId,
        },
      });
    },

    getPagingtList() {
      common.getGuideList(this.currentPage, this.ywType).then((res) => {
        this.total = res.total; // 赋值数据总数
        this.tweetsList = res.rows; // 赋值数据列表
      });
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },

  watch: {
    //监听页码变化，如果变化了获取对应页码的数据
    currentPage(newval, oldval) {
      if (newval !== oldval) {
        this.getPagingtList();
      }
    },
  },
};
</script>


<style scoped>
.announcement-component {
  width: 100%;
}

.list {
  height: 512px;
  overflow: hidden;
  overflow-y: auto;
}

.list::-webkit-scrollbar {
  display: none;
}

.affiche-item {
  display: flex;
  line-height: 46px;
  justify-content: space-between;
  align-items: center;
}

.affiche-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  opacity: 1;
  flex: 1;
  /* 一行显示,超出隐藏 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  cursor: pointer;
}
/* 标题高亮样式 */
.title-active {
  color: #e01811;
  text-decoration: underline;
}

.affiche-date {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #999999;
  opacity: 1;
}

.paging-btns {
  width: 100%;
  height: 30px;
  margin-top: 37px;
  display: flex;
  align-items: center;
}

.left-statistical {
  color: #606266;
  font-size: 14px;
  font-weight: 400;
  color: #606266;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
}

i {
  font-style: normal;
}

.record-sum {
  margin-right: 20px;
}

.rigth-paging {
  display: flex;
}
.rigth-paging /deep/.van-pagination__item {
  color: #333333;
  width: 30px;
  height: 30px;
  border: 1px solid #eeeeee;
  opacity: 1;
  border-radius: 2px;
  margin: 0px 5px;
  box-sizing: border-box;
}

.rigth-paging /deep/.van-pagination__page {
  background: #ffffff;
}

.rigth-paging /deep/.van-pagination__item--active {
  color: #ffffff;
  width: 30px;
  height: 30px;
  background: #e01811;
  opacity: 1;
  border-radius: 2px;
  box-sizing: border-box;
}

.rigth-paging /deep/.van-pagination__prev {
  background-color: transparent;
}
.rigth-paging /deep/.van-pagination__next {
  background-color: transparent;
}

.page-sum {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  border-radius: 2px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding: 5px 8px;
  display: flex;
  align-items: center;
}

.bottom-arrow {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/images/bottom-arrow-icon.png) no-repeat 100%/100%;
  margin-left: 2px;
}

.appoint-page {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #333333;
  opacity: 1;
  margin-left: 18px;
}
.appoint-page-input {
  width: 48px;
  height: 30px;
  color: #333333;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 2px;
  outline: none;
  /* border: none; */
  text-align: center;
  border-radius: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0px 6px;
}

.appoint-page-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
